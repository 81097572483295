<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Union Européenne</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2019 - 2020</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end, webdesign, prise de contenu, interview
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Au cours de mon troisième semestre de DUT Métiers du Multimédia et
						de l'Internet, j'ai eu l'occasion de participer au projet européen
						Open Access avec l'aide de Pierre Durand, Alexis Lartot et de notre
						tuteur Monsieur Augier.<br /><br />
						L’apparition des technologies numériques et d’Internet a eu un
						impact énorme sur le secteur culturel. Celles-ci sont de plus en
						plus utilisées dans le domaine culturel, modifiant le processus de
						création artistique. Open Access souhaite donc développer des
						projets transmédia et expérimenter de nouvelles méthodes de travail.
						L’objectif du projet est de promouvoir la culture en Europe en
						expérimentant les arts de la scène. L’expérimentation est réalisée
						en associant le domaine du spectacle à la création transmédia.
						Celle-ci se déroule lors des quatre laboratoires d’expérimentation
						et de prototypages organisés au cours du projet.<br /><br />
						Lors de ce projet, nous avons pu faire la rencontre des artistes du
						projet Open Access lors du laboratoire qui s'est déroulé à Lisbonne
						auquel nous avons participé et où nous avons eu l'occasion de les
						interviewer (en anglais). Notre rôle a donc été de réaliser des
						interviews mais aussi de capter du contenu (photos, vidéos, vidéos
						360°...) pour ensuite pouvoir les trier et les publier sur le
						Webdocumentaire du projet que nous avons repris et modifié. En
						effet, le Webdocumentaire avait déjà été réalisé en VueJS, nous
						n'avons donc pas eu beaucoup besoin de faire de développement.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a href="http://www.opendoc-project.eu/" class="boutonVoirSite"
							>LE SITE DU WEBDOCUMENTAIRE</a
						>
					</div>

					<!--Image 1-->

					<img
						src="../assets/openaccess/open_access_webdoc.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu du Webdocumentaire auquel nous
                avons participé à la création, le site final n'a pas encore été mis en ligne"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu du Webdocumentaire auquel nous avons participé à la création,
						le site final n'a pas encore été mis en ligne
					</p>

					<!--Image 2-->

					<img
						src="../assets/openaccess/open_access_interview.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Photo de l'interview de Raluca Croitoru
                réalisé par Antonin Winterstein (à gauche) et Sébastien Augier (à droite)"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Photo de l'interview de Raluca Croitoru réalisé par Antonin
						Winterstein (à gauche) et Sébastien Augier (à droite)
					</p>

					<!--Image 3-->

					<img
						src="../assets/openaccess/open_access_conference.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Photo d'une conférence par Francisco
                Guimarães, chercheur et artiste en réalité augmentée et en storytelling"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Photo d'une conférence par Francisco Guimarães, chercheur et artiste
						en réalité augmentée et en storytelling
					</p>

					<!--Image 4-->

					<img
						src="../assets/openaccess/open_access_prototype.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Photo de la démonstration du début du
                prototype de deux artistes du projet : Olinda Favas et João Estevens"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Photo de la démonstration du début du prototype de deux artistes du
						projet : Olinda Favas et João Estevens
					</p>

					<!--Image 5-->

					<img
						src="../assets/openaccess/open_access_photo_groupe.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Photo à Lisbonne d'Antonin Winterstein
                (au centre),
                Alexis Lartot (à gauche) et Pierre Durand (à droite) lors de la phase
                de dérushage"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Photo à Lisbonne d'Antonin Winterstein (au centre), Alexis Lartot (à
						gauche) et Pierre Durand (à droite) lors de la phase de dérushage
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Open Access",
			meta: [
				{
					name: "description",
					content:
						'Découvrez le webdocumentaire du projet européen "Open Access" auquel Antonin Winterstein a contribué !',
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "OPEN ACCESS - WEBDOCUMENTAIRE",
				urlImage: require("../assets/projets/fond_open_access.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
